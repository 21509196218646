import { HashRouter as BrowserRouter } from 'react-router-dom'
import Routes from './Routes'
import { SnackbarProvider } from 'notistack'
import ScrollToTop from './components/ScrollToTop'
import Loader from './components/Loader'
import { Suspense, useEffect } from 'react'
import ThemeProvider from './theme'
import { styled } from '@mui/material'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import './App.css'
import './global.scss'
import AOS from 'aos'
import 'aos/dist/aos.css'
import { AnimatePresence } from 'framer-motion'
import { fetchRequestInterceptor } from './utils/requestInterceptor'

function App() {
  const StyledSnackbarProvider = styled(SnackbarProvider)`
    &.notistack-MuiContent-success {
      background-color: #000000;
    }
    ,
    &.notistack-MuiContent {
      box-shadow: 0px 3px 2px -2px rgba(0, 0, 0, 0.06),
        0px 5px 3px -2px rgba(0, 0, 0, 0.02);
      font-weight: 400;
      border-radius: 8px;
      border: 1px solid rgba(255, 255, 255, 0.2);
    }
  `
  useEffect(() => {
    AOS.init({ duration: 1000 })
    AOS.refresh()
    if (window) {
      fetchRequestInterceptor()
    }
  }, [])
  return (
    <BrowserRouter>
      <ThemeProvider>
        <ScrollToTop />
        <StyledSnackbarProvider>
          <Suspense fallback={<Loader />}>
            {/* <AnimatePresence mode="wait" initial={true}> */}
            <Routes />
            {/* </AnimatePresence> */}
          </Suspense>
        </StyledSnackbarProvider>
      </ThemeProvider>
    </BrowserRouter>
  )
}

export default App
